import { SecureWalletUxMode, WalletNetwork } from '@moonpay/login-common';
import { sendMessageToParent } from 'src/messages/Message';
import registerHandlers from 'src/messages/registerHandlers';
import { ErrorManager } from 'src/utils/errorManager';
import getThemeConfig from 'src/utils/theme/getThemeConfig';
import { loadFont } from 'src/utils/theme/loadFont';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import { BitcoinWallet } from 'src/wallet/services/BitcoinWallet';
import { EthereumWallet } from 'src/wallet/services/EthereumWallet';
import { SolanaWallet } from 'src/wallet/services/SolanaWallet';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { AbstractWallet, CryptoWallet } from 'src/wallet/types/Wallet';
import { KmsWalletProvider } from 'src/wallet/walletProvider/kms/KmsWalletProvider';
import { getAllowedAncestorOrigins } from 'src/wallet/walletProvider/kms/kmsApi';
import logger, { InSpan } from '../../utils/logger';

const errorManager = new ErrorManager(__filename);

export class WalletService {
  public static cryptoWalletFactory(network: WalletNetwork): CryptoWallet {
    switch (network) {
      case WalletNetwork.Ethereum:
        return new EthereumWallet();
      case WalletNetwork.Bitcoin:
        return new BitcoinWallet();
      case WalletNetwork.Solana:
        return new SolanaWallet();
      case WalletNetwork.Ripple:
        // Uses wallets core in @Controller instead
        return {} as any;
      default:
        throw errorManager.getServerError(
          'cryptoWalletFactory',
          'Unsupported network was passed through to the cryptoWalletFactory, this should never happen',
          { network },
        );
    }
  }

  @InSpan()
  public static async restoreWallet(
    network: WalletNetwork,
    walletStorage: WalletStorage,
  ): Promise<AbstractWallet | undefined> {
    const kmsProvider = new KmsWalletProvider();
    return kmsProvider.restore(network, walletStorage);
  }

  public static async bootstrapSecureWallet(
    promptRef: React.RefObject<HTMLElement>,
  ) {
    const { searchParams } = new URL(window.location.href);

    const {
      uxMode,
      apiKey,
      isHeadless,
      initialNetworkEth,
      initialNetworkBtc,
      initialNetworkSol,
      noModal,
      themeId,
      colorScheme,
      sessionId,
    } = WalletHelpers.parseQueryParams(searchParams);
    if (sessionId) {
      logger.setGlobalContext({
        session_id: sessionId,
        apiKey: apiKey || undefined,
      });
    }

    const walletStorage = new WalletStorage();

    // if mode set local storage to mode
    if (colorScheme) {
      localStorage.setItem('color-scheme', colorScheme);
    }

    // resets storage on load
    walletStorage.reset();

    const origins = await getAllowedAncestorOrigins(apiKey);
    registerHandlers({
      walletStorage,
      searchParams,
      promptRef,
      origins,
      autoApprove: isHeadless,
      initialNetworkEth,
      initialNetworkBtc,
      initialNetworkSol,
      noModal,
    });

    // In prompt mode, we need to send a ready message to the parent
    // and wait for an ack before we can proceed.
    //
    // Otherwise, its possible to cause a race condition where the
    // parent sends a message before the sdk has finished initializing
    // and started listening for messages. This would cause the sdk to
    // hang indefinitely.
    if (uxMode === SecureWalletUxMode.Prompt) {
      let ackReceived = false;

      // listen for an ack message from parent
      const handleMessage = async (event: MessageEvent) => {
        const { data } = event;
        if (data.type === 'ack') {
          ackReceived = true;
        }
      };

      // add event listener
      window.addEventListener('message', handleMessage);

      // send the ready over and over again until we get an ack
      const sendReady = async () => {
        try {
          sendMessageToParent('ready', origins);
        } catch (e) {
          logger.error('Error sending ready message to parent', {
            error: e,
          });
        }

        if (!ackReceived) {
          await new Promise((resolve) => setTimeout(resolve, 50));
          await sendReady(); // recursively call sendReady
        }
      };

      // if ack is never received, we'll timeout after 15 seconds
      // this makes us backwards compatible with older versions of the wallet
      // that don't send an ack
      const timeout = setTimeout(() => {
        if (!ackReceived) {
          ackReceived = true;
        }
      }, 3_000);

      // start the recursive sending process
      await sendReady();

      // clear the timeout
      clearTimeout(timeout);

      // remove event listener
      window.removeEventListener('message', handleMessage);
    } else {
      sendMessageToParent('ready', origins);
    }

    // parse theme from query params
    const theme = await getThemeConfig({ themeId });
    const { fontFamily, fontUrl } = theme.themeConfig.config;
    if (fontFamily && fontUrl) await loadFont({ fontFamily, fontUrl });

    return {
      uxMode,
      apiKey,
      walletStorage,
      theme,
    };
  }
}
