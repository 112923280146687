import {
  AccessCurrencyRequestIcon,
  Icon,
  Text,
  WalletAddressIcon,
  WhiteCheckGreenCircle,
} from '@moonpay-widget/ui-kit';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';
import PromptFooter from 'src/v2/common/prompts/prompt-footer';
import PromptSheet from 'src/v2/common/prompts/prompt-sheet';
import { RippleStablecoinCurrency } from '../types';

export interface ApproveCurrencyProps {
  currency: RippleStablecoinCurrency;
  onApprove: () => void;
  onReject: () => void;
}

export const ApproveCurrencyPrompt: FC<ApproveCurrencyProps> = ({
  onApprove,
  onReject,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon
            icon={AccessCurrencyRequestIcon}
            className="w-16 h-16 rounded-xl"
          />
        </div>

        <Text className="mt-4 mb-6 text-center" variant="title1" bold>
          {t(strings.prompts.enableCurrency.title, { currency })}
        </Text>

        <Text
          variant="subhead"
          className="justify-center text-left text-label-secondary mt-5 mb-2"
        >
          {t(strings.prompts.enableCurrency.subTitle)}
        </Text>

        <div className="px-4 pt-6 pb-2 mb-8 bg-system-background-secondary rounded-xl grid gap-y-4">
          <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
            <div className="flex justify-left">
              <Icon icon={WalletAddressIcon} className="w-5 h-5" />
              <Text variant="footnote" className="px-2">
                {t(strings.prompts.enableCurrency.enableStablecoins)}
              </Text>
            </div>
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>

          <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
            <div className="flex justify-left">
              <Icon icon={WalletAddressIcon} className="w-5 h-5" />
              <Text variant="footnote" className="px-2">
                {t(strings.prompts.enableCurrency.enableCurrency, { currency })}
              </Text>
            </div>
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
        </div>

        <PromptFooter
          approveButtonText={t(
            strings.prompts.enableCurrency.footer.approvalButtonText,
          )}
          cancelButtonText={t(
            strings.prompts.enableCurrency.footer.cancelButtonText,
          )}
          aboveButtonText={t(strings.prompts.enableCurrency.footer.title)}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
