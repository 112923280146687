export interface SecureWalletErrorCode {
  code: string;
  message: string;
}

export const SecureWalletCommonErrors = {
  USER_REJECTED_REQUEST: {
    code: 'USER_REJECTED_REQUEST',
    message: 'User rejected request',
  },
  BAD_REQUEST: {
    code: 'BAD_REQUEST',
    message: 'Bad request',
  },
  INTERNAL_ERROR: {
    code: 'INTERNAL_ERROR',
    message: 'Internal error',
  },
  WALLET_DETAILS_FETCH_ERROR: {
    code: 'WALLET_DETAILS_FETCH_ERROR',
    message: 'Error fetching wallet details',
  },
  WALLET_RESTORE_ERROR: {
    code: 'WALLET_RESTORE_ERROR',
    message: 'Error restoring wallet',
  },
  WALLET_API_FETCH_ERROR: {
    code: 'WALLET_API_FETCH_ERROR',
    message: 'Error fetching MoonPay services wallet API response',
  },
} as const;

export const SolanaWalletErrors = {
  SOL_WALLET_DECODE_ERROR: {
    code: 'SOL_WALLET_DECODE_ERROR',
    message: 'Error decoding wallet from base58 string',
  },
  SOL_INVALID_KEYPAIR: {
    code: 'SOL_INVALID_KEYPAIR',
    message: 'Keypair could not be found for the wallet being processed',
  },
  SOL_INS_FUNDS: {
    code: 'SOL_INS_FUNDS',
    message:
      'Sending Solana wallet has insufficient funds to complete the transaction',
  },
  SOL_RECIP_INS_RENT: (message: string) => ({
    code: 'SOL_RECIP_INS_RENT',
    message,
  }),
  SOL_SENDER_INS_RENT: (message: string) => ({
    code: 'SOL_SENDER_INS_RENT',
    message,
  }),
  SOL_UNEXPECTED_RESPONSE: {
    code: 'SOL_UNEXPECTED_RESPONSE',
    message: 'An API endpoint returned data with an unexpected format',
  },
  SOL_TRANSACTION_REQUEST_FAILURE: {
    code: 'SOL_TRANSACTION_REQUEST_FAILURE',
    message:
      'Failed to send Solana transaction via the wallets API, please contact the wallets team',
  },
  SOL_WALLET_API_BALANCE_ERROR: {
    code: 'SOL_WALLET_API_BALANCE_ERROR',
    message: 'Error getting balance from wallet api',
  },
} as const;

export const EvmWalletErrors = {
  EVM_WALLET_API_BALANCE_ERROR: {
    code: 'EVM_WALLET_API_BALANCE_ERROR',
    message: 'Error getting balance from wallet api',
  },
  EVM_WALLET_API_TRANSACTION_COUNT_ERROR: {
    code: 'EVM_WALLET_API_TRANSACTION_COUNT_ERROR',
    message: 'Error getting transaction count from wallet api',
  },
  EVM_WALLET_API_SEND_TRANSACTION_ERROR: {
    code: 'EVM_WALLET_API_SEND_TRANSACTION_ERROR',
    message: 'Error sending a transaction via the wallet api',
  },
  EVM_WALLET_API_GAS_ERROR: {
    code: 'EVM_WALLET_API_GAS_ERROR',
    message: 'Error getting gas from wallet api',
  },
  INVALID_EVM_CHAIN_ID: (chainId: number) => ({
    code: 'INVALID_EVM_CHAIN_ID',
    message: `Invalid EVM chain id: ${chainId}`,
  }),
  NO_EVM_CHAIN_ID_SET: {
    code: 'NO_EVM_CHAIN_ID_SET',
    message: 'No EVM chain id set',
  },
} as const;

export const BitcoinWalletErrors = {
  BTC_WALLET_API_UTXOS_ERROR: {
    code: 'BTC_WALLET_API_UTXOS_ERROR',
    message: 'Error getting utxos from wallet api',
  },
  BTC_WALLET_API_SEND_TXN_ERROR: {
    code: 'BTC_WALLET_API_SEND_TXN_ERROR',
    message: 'Error sending a bitcoin transaction via the wallet api',
  },
  BITCOIN_WALLET_API_BALANCE_ERROR: {
    code: 'BITCOIN_WALLET_API_BALANCE_ERROR',
    message: 'Error getting balance from wallet api',
  },
} as const;

export const RippleWalletErrors = {
  RIPPLE_CURRENCY_NOT_APPROVED: {
    code: 'RIPPLE_CURRENCY_NOT_APPROVED',
    message: 'Currency has not been approved',
  },
} as const;
