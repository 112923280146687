import dayjs from 'dayjs';
import { RippleStablecoinCurrency } from './types';
import StorageUtils from '../../utils/storage';
import { MoonPayWalletError, RippleWalletErrors } from '../common/errors';

export async function hasUserApprovedRippleCurrency(
  currency: RippleStablecoinCurrency,
) {
  const value = await StorageUtils.get('rippleCurrencyApprove');

  if (value) {
    const data = JSON.parse(value);

    if (data.currency === currency || dayjs().isBefore(data.expiresAt)) {
      return;
    }
  }

  throw new MoonPayWalletError(RippleWalletErrors.RIPPLE_CURRENCY_NOT_APPROVED);
}
