import {
  BitcoinSendTransactionPrompt as BitcoinSendTransactionPromptComponent,
  BitcoinSwitchNetworkPrompt as BitcoinSwitchNetworkPromptComponent,
} from 'src/v2/bitcoin/prompts';
import {
  ERC20SendTransactionPrompt as EvmErc20SendTransactionPromptComponent,
  EvmSendTransactionPrompt as EvmSendTransactionPromptComponent,
  EvmSwitchNetworkPrompt as EvmSwitchNetworkPromptComponent,
} from 'src/v2/evm/prompts';
import {
  SolanaSigningMessagePrompt,
  SolanaSwitchNetworkPrompt as SolanaSwitchNetworkPromptComponent,
  SolanaTransferTransactionPrompt,
} from 'src/v2/solana/prompts';
import { ApproveConnectionPrompt } from './approve-connection.prompt';
import { ApproveCurrencyPrompt } from '../../ripple/prompts/approve-currency.prompt';

export enum PromptType {
  EvmSendTransactionPrompt = 'EvmSendTransactionPrompt',
  EvmErc20SendTransactionPrompt = 'EvmErc20SendTransactionPrompt',
  EvmSwitchNetworkPrompt = 'EvmSwitchNetworkPrompt',
  SolanaConnectPrompt = 'SolanaConnectPrompt',
  SolanaSwitchNetworkPrompt = 'SolanaSwitchNetworkPrompt',
  SolanaTransferPrompt = 'SolanaTransferPrompt',
  SolanaSignMessagePrompt = 'SolanaSignMessagePrompt',
  BitcoinAccountsPrompt = 'BitcoinAccountsPrompt',
  BitcoinSwitchNetworkPrompt = 'BitcoinSwitchNetworkPrompt',
  BitcoinSendTransactionPrompt = 'BitcoinSendTransactionPrompt',
  RippleApproveCurrency = 'RippleApproveCurrency',
}

export const promptMap = {
  [PromptType.EvmSendTransactionPrompt]: EvmSendTransactionPromptComponent,
  [PromptType.EvmErc20SendTransactionPrompt]:
    EvmErc20SendTransactionPromptComponent,
  [PromptType.EvmSwitchNetworkPrompt]: EvmSwitchNetworkPromptComponent,
  [PromptType.SolanaConnectPrompt]: ApproveConnectionPrompt,
  [PromptType.SolanaSwitchNetworkPrompt]: SolanaSwitchNetworkPromptComponent,
  [PromptType.SolanaTransferPrompt]: SolanaTransferTransactionPrompt,
  [PromptType.SolanaSignMessagePrompt]: SolanaSigningMessagePrompt,
  [PromptType.BitcoinAccountsPrompt]: ApproveConnectionPrompt,
  [PromptType.BitcoinSwitchNetworkPrompt]: BitcoinSwitchNetworkPromptComponent,
  [PromptType.BitcoinSendTransactionPrompt]:
    BitcoinSendTransactionPromptComponent,
  [PromptType.RippleApproveCurrency]: ApproveCurrencyPrompt,
};
