/* eslint-disable no-new */
import 'reflect-metadata';
import { EvmController } from 'src/v2/evm/evm.controller';
import { SolanaController } from 'src/v2/solana/solana.controller';
import { WalletStorage } from '../wallet/storage/WalletStorage';
import { BitcoinController } from './bitcoin/bitcoin.controller';
import { LegacySolanaController } from './solana/legacy-solana.controller';
import { CommonWalletsController } from './common/common-wallets.controller';
import { RippleController } from './ripple/ripple.controller';

const walletStorage = new WalletStorage();

new LegacySolanaController(walletStorage);
new SolanaController(walletStorage);
new BitcoinController(walletStorage);
new EvmController(walletStorage);
new RippleController();
new CommonWalletsController();
