import { WalletNetwork } from '@moonpay/login-common';
import { ActiveChainsData } from 'src/wallet/storage/ActiveChainStorage';
import {
  NetworkEvmChain,
  NetworkRippleChain,
  NetworkSolChain,
} from '../../types/NetworkChain';

export const DEFAULT_ACTIVE_CHAINS: ActiveChainsData = {
  [WalletNetwork.Ethereum]: NetworkEvmChain.Mainnet, // eth - mainnet
  [WalletNetwork.Bitcoin]: 0, // btc - mainnet
  [WalletNetwork.Solana]: NetworkSolChain.Mainnet,
  [WalletNetwork.Ripple]: NetworkRippleChain.Mainnet,
};
