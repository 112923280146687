import { WalletNetwork } from '@moonpay/login-common';
import StorageUtils from 'src/utils/storage';
import { Controller, Params, PostMessage, Response } from '../lib/decorators';
import { MoonPayWalletError, SecureWalletCommonErrors } from './errors';
import { GetWalletsRequest } from './requests/get-wallets.request';
import {
  GetWalletsResponse,
  IGetWalletsResponse,
} from './responses/get-wallets.response';
import { WalletsCoreFactory } from './services/wallets-core-factory';
import { CommonControllerMethods } from './types';

@Controller()
export class CommonWalletsController {
  @PostMessage(CommonControllerMethods.GetWallets)
  @Params(GetWalletsRequest)
  @Response(GetWalletsResponse)
  public async getWallets(
    body: GetWalletsRequest,
  ): Promise<IGetWalletsResponse> {
    if (body.csrfToken) {
      await StorageUtils.set('csrfToken', body.csrfToken);
    }

    const walletsCore = await WalletsCoreFactory.getInstance({
      csrfToken: body.csrfToken,
    });

    // Driving ripple generation via client requesting it
    const requestedNetworks = body.networks.length
      ? body.networks
      : [
          WalletNetwork.Bitcoin,
          WalletNetwork.Ethereum,
          WalletNetwork.Solana,
          WalletNetwork.Ripple,
        ];

    const walletNetworkMap = {
      [WalletNetwork.Bitcoin]: 'bitcoin',
      [WalletNetwork.Ethereum]: 'evm',
      [WalletNetwork.Solana]: 'solana',
      [WalletNetwork.Ripple]: 'ripple',
    };

    const wallets = await Promise.all(
      requestedNetworks.map(async (network) => {
        const networkWallet = walletNetworkMap[network];

        if (!networkWallet) {
          throw new MoonPayWalletError(SecureWalletCommonErrors.BAD_REQUEST);
        }

        const walletAddress = (
          await walletsCore.getNetworkWallet(networkWallet)
        ).address as string;

        return {
          address: walletAddress,
          network,
        };
      }),
    );

    return { wallets };
  }
}
