/* eslint-disable no-restricted-syntax */
import Cookies, { CookieAttributes } from 'js-cookie';
import { isSameSiteNoneCompatible } from 'should-send-same-site-none';
import { getItem, removeItem, setItem } from './localforage';
import logger from './logger';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ga: any;
    gtag: any;
    OneTrust: any;
    OnetrustActiveGroups: string;
  }
}

type StorageNameKey =
  | '_fbc'
  | '_fbp'
  | 'apiKey'
  | 'cookieTest'
  | 'countryCode'
  | 'countryCodeAlpha2'
  | 'csrfToken'
  | 'customerId'
  | 'defaultBaseCurrencyCode'
  | 'ipAddressCurrencyCode'
  | 'isEdgeSafariWebview'
  | 'isNftAccount'
  | 'ld_device_id'
  | 'ld_session_id'
  | 'OptanonConsent'
  | 'scamWarningViewed'
  | 'sessionId'
  | 'stateCode'
  | 'yellowCardIntroductionBannerSeen'
  | 'customerToken'
  | 'walletToken'
  | 'rippleCurrencyApprove';

export default class StorageUtils {
  public static async set(
    name: StorageNameKey,
    value: string,
    options: CookieAttributes = {},
  ): Promise<void> {
    // Set in indexeddb first
    await setItem(name, value);

    // we set the cookie for backward compatibility
    try {
      Cookies.set(name, value, {
        ...this.getDefaultCookieOptions(),
        ...options,
      });
    } catch (e) {
      logger.error(
        '[StorageUtils] Error setting cookie: ',
        undefined,
        e as Error,
      );
    }
  }

  public static async get(name: StorageNameKey): Promise<string | undefined> {
    // default to get from indexeddb first
    const val: string | undefined = (await getItem(name)) || undefined;
    if (val) {
      return val;
    }

    // if not found in indexeddb, check cookies
    // this ensures backward compatibility
    // Only do this if we are using cookie
    return Cookies.get(name);
  }

  public static async getOrSetItem(
    name: StorageNameKey,
    value: string,
    options: CookieAttributes = {},
  ): Promise<string> {
    const currentValue = await this.get(name);
    if (typeof currentValue !== 'undefined') {
      return currentValue;
    }

    await this.set(name, value, options);
    return value;
  }

  public static async removeItem(name: string): Promise<void> {
    await removeItem(name);

    Cookies.remove(name, this.getDefaultCookieOptions());
  }

  private static getDefaultCookieOptions(): CookieAttributes {
    const shouldSameSite =
      process.env.REACT_APP_SAME_SITE_NONE === 'true' ||
      process.env.NODE_ENV === 'production';

    return {
      ...(navigator &&
        navigator.userAgent &&
        isSameSiteNoneCompatible(navigator.userAgent) && {
          sameSite: shouldSameSite ? 'none' : 'strict',
          secure: shouldSameSite,
        }),
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
    };
  }

  public static async setCustomerToken(token: string): Promise<void> {
    /**
     * This method is only called by buy-widget via postMessage
     * */
    await setItem('customerToken', token);
  }

  /**
   * Sets the wallet token asynchronously.
   * This method is only called by buy-widget via postMessage
   * @param token The wallet token to set.
   * @returns A Promise that resolves when the token is set.
   * */
  public static async setWalletToken(token: string): Promise<void> {
    await setItem('walletToken', token);
  }

  /**
   * This method is only called by buy-widget via postMessage
   * */
  public static async setCsrfToken(token: string): Promise<void> {
    await this.set('csrfToken', token, { domain: '.moonpay.com' });
  }

  public static async getCookieItem(
    name: StorageNameKey,
  ): Promise<string | undefined> {
    // Only do this if we are using cookie
    const cookieVal = Cookies.get(name);
    return cookieVal;
  }

  public static async clearCustomerTokens() {
    await this.removeItem('csrfToken');
    await this.removeItem('customerToken');
    await this.removeItem('customerId');
  }

  public static async clearWalletToken() {
    await this.removeItem('walletToken');
  }

  public static async remove(key: string) {
    await this.removeItem(key);
  }
}
