export enum RippleNetworkEnvironment {
  Mainnet = 'mainnet',
  Testnet = 'testnet',
  Local = 'local',
}

export enum RippleMethods {
  ApproveCurrency = 'ripple_approveCurrency',
  EnableStablecoins = 'ripple_enableStablecoins',
  EnableCurrency = 'ripple_enableCurrency',
}

export enum RippleStablecoinCurrency {
  RLUSD = 'RLUSD',
}

export interface IRippleClient {
  address: string;
  mnemonic: string;
  approveCurrency(currency: RippleStablecoinCurrency): Promise<string>;
  approveStableCoins(): Promise<string>;
}
