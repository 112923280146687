import { WalletNetwork } from '@moonpay/login-common';
import dayjs from 'dayjs';
import { Controller, Params, PostMessage, Response } from '../lib/decorators';
import { type IRippleClient, RippleMethods } from './types';
import { PromptManager } from '../common/prompts';
import { PromptType } from '../common/prompts/prompt-map';
import { RippleApproveCurrencyResponse } from './responses/ripple-approve-currency.response';
import { RippleStablecoinRequest } from './requests/ripple-stablecoin.request';
import { RippleTransactionResponse } from './responses/ripple-transaction.response';
import StorageUtils from '../../utils/storage';
import { hasUserApprovedRippleCurrency } from './ripple-controller-helpers';

@Controller()
export class RippleController {
  private readonly promptManager: PromptManager;

  constructor() {
    this.promptManager = new PromptManager({ network: WalletNetwork.Ripple });
  }

  @Params(RippleStablecoinRequest)
  @PostMessage(RippleMethods.ApproveCurrency)
  @Response(RippleApproveCurrencyResponse)
  public async approveCurrency(
    _wallet: IRippleClient,
    { currency }: RippleStablecoinRequest,
  ): Promise<RippleApproveCurrencyResponse> {
    try {
      await this.promptManager.showPrompt(PromptType.RippleApproveCurrency, {
        currency,
      });

      await StorageUtils.set(
        'rippleCurrencyApprove',
        JSON.stringify({
          currency,
          expiresAt: dayjs().add(5, 'minutes'),
        }),
      );

      return { approved: true };
    } catch {
      return { approved: false };
    }
  }

  @Params(RippleStablecoinRequest)
  @PostMessage(RippleMethods.EnableStablecoins)
  @Response(RippleTransactionResponse)
  public async enableStablecoins(
    wallet: IRippleClient,
    { currency }: RippleStablecoinRequest,
  ) {
    await hasUserApprovedRippleCurrency(currency);

    return {
      hash: await wallet.approveStableCoins(),
    };
  }

  @Params(RippleStablecoinRequest)
  @PostMessage(RippleMethods.EnableCurrency)
  @Response(RippleTransactionResponse)
  public async enableCurrency(
    wallet: IRippleClient,
    { currency }: RippleStablecoinRequest,
  ) {
    await hasUserApprovedRippleCurrency(currency);

    return {
      hash: await wallet.approveCurrency(currency),
    };
  }
}
