import {
  EthProviderRpcError,
  WalletDetails,
  WalletMessageType,
  WalletNetwork,
} from '@moonpay/login-common';
import { RefObject } from 'react';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import logger from '../utils/logger';
import {
  sendMessageToParent,
  sendWalletMessage,
  sendWalletReply,
} from './Message';
import registerCreateWalletMessageHandler from './walletCreation/registerCreateWalletMessageHandler';
import registerGetWalletMessageHandler from './walletCreation/registerGetWalletMessageHandler';
import registerWalletMessageProxyHandler from './walletProxy/registerWalletMessageProxyHandler';

type Args = {
  walletStorage: WalletStorage;
  searchParams: URLSearchParams;
  promptRef: RefObject<HTMLElement>;
  origins: string[];
  autoApprove: boolean;
  initialNetworkEth: number | boolean;
  initialNetworkBtc: number | boolean;
  initialNetworkSol: number | boolean;
  initialNetworkXrp?: number | boolean;
  noModal: boolean;
};

const registerHandlers = ({
  walletStorage,
  searchParams,
  promptRef,
  origins,
  autoApprove,
  initialNetworkEth,
  initialNetworkBtc,
  initialNetworkSol,
  initialNetworkXrp,
  noModal,
}: Args) => {
  const initialChainIds = {
    [WalletNetwork.Ethereum]:
      typeof initialNetworkEth === 'number'
        ? initialNetworkEth
        : walletStorage.activeChainId.getActiveChainIdByNetwork(
            WalletNetwork.Ethereum,
          ),
    [WalletNetwork.Bitcoin]:
      typeof initialNetworkBtc === 'number'
        ? initialNetworkBtc
        : walletStorage.activeChainId.getActiveChainIdByNetwork(
            WalletNetwork.Bitcoin,
          ),
    [WalletNetwork.Solana]:
      typeof initialNetworkSol === 'number'
        ? initialNetworkSol
        : walletStorage.activeChainId.getActiveChainIdByNetwork(
            WalletNetwork.Solana,
          ),
    [WalletNetwork.Ripple]:
      typeof initialNetworkXrp === 'number'
        ? initialNetworkXrp
        : walletStorage.activeChainId.getActiveChainIdByNetwork(
            WalletNetwork.Ripple,
          ),
  };
  walletStorage.activeChainId.set(initialChainIds);

  registerWalletMessageProxyHandler({
    walletStorage,
    promptRef,
    onPromptChange: (action: string, network: WalletNetwork) => {
      sendWalletMessage(
        {
          type: WalletMessageType.PROMPT_CHANGE,
          result: {
            action,
            network,
          },
        },
        origins,
      );
    },
    onSuccess: (response: any, id?: string) => {
      sendWalletReply({ result: response }, origins, id);
    },
    onError: (error: EthProviderRpcError, id?: string) => {
      // emit a warning since explicit errors are logged upstream
      //
      // note: even when we `logError` early we still `throw` the
      // error so its resurfaced here. In order to avoid double logging
      // the error we should throw a warning here (or avoid all together)
      // TODO: possibly extend error to flag if logged already
      //
      logger.warn('Error occurred when handling wallet message proxy', {
        error,
        id,
        origins,
        chainId: walletStorage.activeChainId.getActiveChains(),
        searchParams,
      });
      sendWalletReply(
        {
          error,
        },
        origins,
        id,
      );
    },
    origins,
    autoApprove,
    // TODO mnemonic for test only purposes, delete it later
    mnemonic: searchParams.get('mnemonic') || '',
    noModal,
  });

  registerCreateWalletMessageHandler({
    walletStorage,
    onSuccess: (wallets: WalletDetails, id?: string) => {
      wallets.wallets.forEach((wallet: any) => {
        logger.info(`Successfully created wallet`, {
          wallet,
          chainId: walletStorage.activeChainId.getActiveChains(),
          searchParams,
          id,
        });
      });

      sendMessageToParent<WalletDetails>(
        'create-wallet-success',
        origins,
        wallets,
        id,
      );
    },
    onError: (error: EthProviderRpcError, id?: string) => {
      // emit a warning since explicit errors are logged upstream
      //
      // note: even when we `logError` early we still `throw` the
      // error so its resurfaced here. In order to avoid double logging
      // the error we should throw a warning here (or avoid all together)
      // TODO: possibly extend error to flag if logged already
      //
      logger.warn('Error occurred when handling create wallet message', {
        error,
        origins,
        chainId: walletStorage.activeChainId.getActiveChains(),
        searchParams,
        id,
      });
      sendMessageToParent('create-wallet-error', origins, { error }, id);
    },
    origins,
  });

  registerGetWalletMessageHandler({
    walletStorage,
    // TODO: improve this; returns undefined if wallets created already - Josh
    onSuccess: (wallets: WalletDetails | undefined, id?: string) => {
      sendMessageToParent<WalletDetails>(
        'get-wallet-success',
        origins,
        wallets,
        id,
      );
    },
    onError: (error: EthProviderRpcError, id?: string) => {
      // emit a warning since explicit errors are logged upstream
      //
      // note: even when we `logError` early we still `throw` the
      // error so its resurfaced here. In order to avoid double logging
      // the error we should throw a warning here (or avoid all together)
      // TODO: possibly extend error to flag if logged already
      //
      logger.warn('Error occurred when handling get wallet message', {
        error: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
        origins,
        chainId: walletStorage.activeChainId.getActiveChains(),
        searchParams: searchParams.toString(),
        id,
      });

      sendMessageToParent('get-wallet-error', origins, { error }, id);
    },
    origins,
  });
};

export default registerHandlers;
